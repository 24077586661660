export const fields = [

    { key: 'nama_pjbu', label: "Nama PJBU", _style:'min-width:100px' },
    { key: 'nama_badan_usaha', label: "Nama Badan Usaha", _style:'min-width:100px' },
    { key: "alamat", label: "Alamat", _style:'min-width:50px'},
    { key: 'bentuk',label: "Bentuk Badan Usaha", _style:'min-width:100px;' },
    { key: 'asosiasi', label: "Asosiasi", _style:'min-width:100px;' },
    { key: 'sub_klasifikasi_kbli', label: "Sub Klasifikasi KBLI", _style:'min-width:100px;' },
    { key: 'kualifikasi_kbli', label: "Kualifikasi KBLI", _style:'min-width:100px;' },
    { key: 'tanggal_terbit', label: "Tanggal Terbit", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
